'use client';
import { Carousel } from '@v2/components';
import { genreCategoriesList } from '@v2/containers/Header/components/ConcertsDropdown';
import { CONCERTS_ID } from '@v2/views/events/util';
import Image from 'next/image';
import Link from 'next/link';

export const ConcertGenresSection = () => {
  return (
    <div>
      <h1 className="mb-6 text-lg font-bold md:text-2xl">Explore by genre</h1>
      <Carousel>
        {genreCategoriesList.map(({ image, label, slug }) => {
          return (
            <Link
              href={`/events/${slug}?parentId=${CONCERTS_ID[0]}`}
              key={slug}
              className="flex max-h-[214px] flex-shrink-0 flex-grow-0 basis-60 flex-col"
            >
              <div className="mb-3 h-40 w-full overflow-hidden rounded-lg">
                <Image
                  src={image}
                  alt={label}
                  width="0"
                  height="0"
                  sizes="246px"
                  loading="lazy"
                  className="h-[160px] w-[246px] rounded-lg object-cover object-center"
                />
              </div>
              <div className="w-full max-w-64">
                <h2 className="mb-2 text-sm font-semibold md:text-base">
                  {label}
                </h2>
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};
